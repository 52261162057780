import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Faq from '../Elements/Faq';
import { useModal } from "../../context/ModelContext";

const ProjectsLogisitics = () => {
    const { handleShow } = useModal();
    return (
            <>
                <Header />
                <div className="page-content">
                    <div className="section-full p-t80 bg-white">
                    <div className="container">
                        <div className="section-content ">
                            <div className="m-service-containt text-black">
                                <div className="row">
                                    <div className="col-md-5 col-sm-12">
                                        <div className="service-about-left">
                                            <div className="mt-media">
                                                <img src={require('./../../images/services/5.jpg')} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-sm-12">
                                        <div className="service-about-right m-b30">
                                            <h3 className="m-t0">Project Logistic</h3>
                                            <p>Time Global Shipping specializes in managing complex and oversized cargo projects, offering tailored logistics solutions to meet your unique requirements. Our project logistics services cover every aspect of transportation, including planning, route surveys, load supervision, and multimodal transport. We handle heavy machinery, industrial equipment, and other challenging shipments with precision and care, ensuring safe and timely delivery to destinations worldwide. With our team of experts and a global network of partners, we manage the complexities of large-scale projects, providing seamless coordination and execution.
                                            </p>
                                            <p>
                                            We pride ourselves on delivering cost-effective and efficient solutions, regardless of the size or scope of your project. From initial consultation to final delivery, we work closely with you to ensure that every detail is accounted for. Our focus on reliability and innovation allows us to tackle even the most demanding logistics challenges, helping your business achieve its goals without delay. Trust Time Global Shipping to provide expert project logistics services that drive success and efficiency in your operations.
                                            </p>
                                            <div>
                                                <button
                                                    className="m-b15 site-button btn-effect m-r15 text-uppercase"
                                                    type="button"
                                                    onClick={() => {
                                                        handleShow();
                                                    }}
                                                >
                                                    Get a Quote
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Faq />
                <Footer />
                    
                </div>

                
               

            </>
    );
};

export default ProjectsLogisitics;