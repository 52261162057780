import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Faq from '../Elements/Faq';
import { useModal } from "../../context/ModelContext";
const AirFreight = () => {
    const { handleShow } = useModal();
    return (
            <>
                <Header />
                <div className="page-content">
                    <div className="section-full p-t80 bg-white">
                    <div className="container">
                        <div className="section-content ">
                            <div className="m-service-containt text-black">
                                <div className="row">
                                    <div className="col-md-5 col-sm-12">
                                        <div className="service-about-left">
                                            <div className="mt-media">
                                                <img src={require('./../../images/services/2.jpg')} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-sm-12">
                                        <div className="service-about-right m-b30">
                                            <h3 className="m-t0">Air Freight</h3>
                                            <p>At Time Global Shipping, we understand the importance of speed and reliability when it comes to time-sensitive shipments. Our air freight solutions are designed to deliver your cargo swiftly and securely, no matter the destination. We offer a range of options, from small packages to large and critical consignments, ensuring flexibility to meet your specific needs. With our global network of partners and advanced tracking systems, you’ll have complete visibility of your shipments at every stage. Our expert team handles all aspects of air freight, including customs clearance, documentation, and cargo insurance, guaranteeing a seamless and stress-free experience.</p>
                                            <p>
                                            Whether you need express services for urgent deliveries or cost-effective solutions for regular shipments, we provide customized air freight services that optimize your supply chain. Our focus on efficiency and precision ensures that your goods reach their destination on time and in perfect condition. By leveraging strategic partnerships with leading airlines, we offer competitive rates and priority handling for your cargo. Trust Time Global Shipping to provide fast, reliable, and secure air freight services, keeping your business moving at the speed of today’s global market.
                                            </p>
                                            <div>
                                                <button
                                                    className="m-b15 site-button btn-effect m-r15 text-uppercase"
                                                    type="button"
                                                    onClick={() => {
                                                        handleShow();
                                                    }}
                                                >
                                                    Get a Quote
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                    <Faq />
                <Footer />
            </>
    );
};

export default AirFreight;