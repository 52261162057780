import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import { NavLink } from 'react-router-dom';
import Faq from '../Elements/Faq';
import { useModal } from "../../context/ModelContext";

const ContainerTraining = () => {
    const { handleShow } = useModal();
    return (
            <>
                <Header />
                <div className="page-content">
                    <div className="section-full p-t80 bg-white">
                    <div className="container">
                        <div className="section-content ">
                            <div className="m-service-containt text-black">
                                <div className="row">
                                    <div className="col-md-5 col-sm-12">
                                        <div className="service-about-left">
                                            <div className="mt-media">
                                                <img src={require('./../../images/services/4.jpg')} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-sm-12">
                                        <div className="service-about-right m-b30">
                                            <h3 className="m-t0">Container Training</h3>
                                            <p>Time Global Shipping offers a wide range of high-quality shipping containers for sale, catering to various logistics and storage requirements. From standard dry containers to specialized units like refrigerated and open-top containers, we provide durable and cost-effective solutions for businesses across industries. Our containers are inspected and certified to meet international safety and quality standards, ensuring reliability for both short-term projects and long-term logistics operations. Whether you need containers for transportation, on-site storage, or custom modifications, we have the right options to meet your needs.</p>
                                            <p>
                                            Our container trading services are backed by a commitment to quality and customer satisfaction. We work closely with clients to understand their specific requirements, offering tailored solutions to optimize their logistics and operations. With a large inventory and competitive pricing, we provide flexible options that support your business’s growth and efficiency. At Time Global Shipping, we take pride in delivering top-notch container solutions that help you navigate the complexities of global trade with ease and confidence.
                                            </p>
                                            <div>
                                                <button
                                                    className="m-b15 site-button btn-effect m-r15 text-uppercase"
                                                    type="button"
                                                    onClick={() => {
                                                        handleShow();
                                                    }}
                                                >
                                                    Get a Quote
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Faq />
                <Footer />
                </div>
               
                </>
    );
};

export default ContainerTraining;