import React from "react";

import logo from "../../images/logo-light.png";

const Loader = () => {
  return (
    <div className="loading-area">
      <div className="loading-box" />
      <div className="loading-pic">
        <div className="cssload-loader">
          <img src={logo} alt="Shapen" />
        </div>
      </div>
    </div>
  );
};

export default Loader;
