import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const images = [
    require('./../../images/gallery/portrait/about.main.1.png'),
    require('./../../images/gallery/portrait/about.main.2.png'),
    require('./../../images/gallery/portrait/about.main.3.png'),
]

var bnr1 = require('./../../images/background/line.png');

class About4 extends React.Component {
    
    componentDidMount(){
        function loadScript(src) {
           
          return new Promise(function(resolve, reject){
            var script = document.createElement('script');
            script.src = src;
            script.addEventListener('load', function () {
              resolve();
            });
            script.addEventListener('error', function (e) {
              reject(e);
            });
            document.body.appendChild(script);
            document.body.removeChild(script);
          })
        };
 
      loadScript('./assets/js/masonary.js');
      
    };
    
    render() {
        const options = {
            loop:true,
            autoplay:true,
            autoplayTimeout:3000,
            margin:30,
            nav:false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            items:1,
            dots: true
        };
        return (
            <>
                <div className="section-full p-t80 p-b80 bg-white inner-page-padding">
                    <div className="container">
                        <div className="section-content ">
                            <div className="row">
                                <div className="col-lg-5 col-md-5 ">
                                    <div className="m-about ">
                                        <OwlCarousel className="owl-carousel about-us-carousel" {...options}>
                                            {images.map((item, index) => (
                                                <div className="item" key={index}>
                                                    <div className="ow-img">
                                                    <NavLink to="/about"><img src={item} alt=""/></NavLink>
                                                    </div>
                                                </div>
                                            ))}
                                        
                                        </OwlCarousel>
                                        
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-7">
                                    <div className="m-about-containt text-black p-t80">
                                        <div className="m-about-years bg-moving" style={{backgroundImage:"url(" + bnr1 + ")"}}>
                                            <span className="text-primary large-title">10</span>
                                            <span className="large-title-info">Years of Experience</span>
                                        </div>
                                        <h3 className="font-weight-600">We specialize in tailored logistics solutions, offering seamless sea and air freight, expert project cargo handling, and secure warehousing..</h3>
                                        <p>At Time Global Shipping, we specialize in delivering seamless, reliable logistics solutions tailored to meet the diverse needs of our clients. Our expertise spans across sea freight, air freight, project cargo, and warehousing, ensuring every shipment is handled with precision and care. With a focus on innovation and customer satisfaction, we strive to streamline supply chains and optimize global trade. Our commitment to excellence and adaptability has made us a trusted partner for businesses worldwide. Together, we navigate complexities to achieve success in an ever-evolving industry.</p>
                                        <div className="author-info p-t20">
                                            <div className="author-name">
                                                <h4 className="m-t0">Subish Surendran</h4>
                                                <p>Founder & Managing Director</p>
                                            </div>
                                            <NavLink to="/about" className="site-button btn-effect m-b15"><span>Contact us</span></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default About4;