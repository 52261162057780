import React from 'react';
import CountUp from 'react-countup';
import { NavLink } from 'react-router-dom';
import ReactPlayer from 'react-player';

var img1 = require('./../../images/video-bg.jpg');

class OurValue extends React.Component {
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/masonary.js');

    };

    render() {
        return (
            <>
                <div className="section-full mobile-page-padding p-t80 p-b30 bg-white">
                    <div className="container">
                        <div className="section-content">
                            <div className="row">
                                <div className="col-md-4 col-sm-12">
                                    {/* TITLE START */}
                                    <div className="section-head">
                                        <div className="mt-separator-outer separator-center">
                                            <div className="mt-separator">
                                                <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary text-center">Our</span> Value</h2>
                                            </div>
                                            <p className='text-center'>At Time Global Shipping, value is at the core of everything we do. We believe in delivering more than just logistics services. we deliver trust, reliability, and peace of mind.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    {/* TITLE START */}
                                    <div className="section-head">
                                        <div className="mt-separator-outer separator-center">
                                            <div className="mt-separator">
                                                <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary text-center">Our</span> MISSION</h2>
                                            </div>
                                            <p className='text-center'>To strategically plan, develop, and continuously elevate our services and facilities to meet the dynamic needs of global logistics. We are unwavering in our commitment to placing our customers at the heart of every decision, ensuring their success is our priority. By fostering a collaborative and innovative working environment, we empower our teams to deliver seamless and efficient supply chain solutions. Our mission aligns with our enduring purpose: to provide a competitive advantage to our clients by optimizing and enhancing their logistics operations.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    {/* TITLE START */}
                                    <div className="section-head">
                                        <div className="mt-separator-outer separator-center">
                                            <div className="mt-separator">
                                                <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Our</span> VISION</h2>
                                            </div>
                                            <p>To be recognized as the most dependable and esteemed partner in the logistics industry, fostering long-term relationships with our clients by supporting their growth and market expansion. Our vision is to pioneer cutting-edge logistics solutions that not only meet but exceed expectations. We offer unparalleled services that empower our clients to achieve their strategic goals. Through our emphasis on adaptability, customized solutions, and an unrelenting focus on excellence, we aim to redefine logistics as a catalyst for success</p>
                                        </div>
                                    </div>
                                    {/* TITLE END */}
                                    <div className="author-info p-t20">
                                        <div className="author-name text-right">
                                            <h4 className="m-t0">Subish Surendran</h4>
                                            <p>Founder & Managing Director</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="myModal2" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <ReactPlayer url='https://vimeo.com/34741214' />
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default OurValue;