import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Faq from "../Elements/Faq";
import { useModal } from "../../context/ModelContext";
const img = require("./../../images/services/1.jpg");
const OceanFreight = () => {
  const { handleShow } = useModal();
  return (
    <>
      <Header />
      <div className="page-content">
        <div className="section-full p-t80 bg-white">
          <div className="container">
            <div className="section-content ">
              <div className="m-service-containt text-black">
                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <div className="service-about-left">
                      <div className="mt-media">
                        <img src={img} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 col-sm-12">
                    <div className="service-about-right m-b30">
                      <h3 className="m-t0">Ocean Freight</h3>
                      <p>
                        At Time Global Shipping, we pride ourselves on offering
                        comprehensive ocean freight solutions that cater to
                        businesses of all sizes. Our services include full
                        container load (FCL) and less than container load (LCL)
                        options, ensuring flexibility and cost-efficiency for
                        your cargo needs. Whether you are importing, exporting,
                        or engaging in cross-trade shipments, our team handles
                        every detail with precision. From port-to-port or
                        door-to-door delivery, we manage all aspects of the
                        shipping process, including customs clearance,
                        documentation, and real-time cargo tracking. With our
                        extensive global network and advanced logistics
                        technology, we guarantee a seamless and reliable
                        shipping experience, delivering your cargo on time and
                        in perfect condition.
                      </p>
                      <p>
                        Our ocean freight services are designed to optimize your
                        supply chain and reduce costs while ensuring the safety
                        and security of your goods. We work closely with a
                        network of reputable carriers to offer competitive rates
                        and flexible scheduling options, adapting to your unique
                        business requirements. Our commitment to customer
                        satisfaction drives us to provide tailored solutions
                        that enhance operational efficiency and streamline
                        logistics processes. Whether you are shipping bulk
                        commodities, specialized equipment, or time-sensitive
                        goods, Time Global Shipping ensures your cargo navigates
                        international waters smoothly, meeting the highest
                        standards of reliability and service excellence. Partner
                        with us for ocean freight solutions that go beyond
                        transportation, delivering peace of mind with every
                        shipment.
                      </p>
                      <div>
                          <button
                            className="m-b15 site-button btn-effect m-r15 text-uppercase"
                            type="button"
                          onClick={() => {
                            handleShow();
                          }}
                        >
                          Get a Quote
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Faq />
        <Footer />
      </div>
    </>
  );
};

export default OceanFreight;
