import React from 'react';
import { NavLink } from 'react-router-dom';

var bnr1 = require('./../../images/background/bg-6.png');

class Specialization5 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-dark mobile-page-padding" style={{backgroundImage:"url(" +  + ")"}}>
                    <div className="container">
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="section-head m-b50 text-white text-center">
                                <h3 className="m-t0"></h3>
                                <span></span>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img src={require('../../images/services/industries/oil.png')}  alt=""/>
                                        <div className="figcaption">
                                            <h4>Oil & Gas</h4>
                                            <p>Specialized solutions for the oil and gas sector, handling heavy-duty equipment and hazardous materials with precision and care.</p>
                                            <NavLink to="/industries/oilgas"><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img src={require('../../images/services/industries/construction.png')} alt="" />
                                        <div className="figcaption">
                                            <h4>Construction</h4>
                                            <p>Comprehensive logistics solutions for the construction industry, transporting heavy machinery, materials, and equipment with ease.</p>
                                            <NavLink to="/industries/construction"><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img src={require('../../images/services/industries/food.png')} alt=""/>
                                        <div className="figcaption bg-dark">
                                            <h4>Food & Beverage</h4>
                                            <p>Temperature-controlled and timely delivery solutions for the food and beverage sector, maintaining freshness and quality throughout the journey.</p>
                                            <NavLink to="/industries/food"><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img src={require('../../images/services/industries/fmcg.png')}  alt=""/>
                                        <div className="figcaption">
                                            <h4>FMCG</h4>
                                            <p>Fast-moving logistics for fast-moving goods, providing efficient and reliable supply chain solutions for the FMCG industry.</p>
                                            <NavLink to="/industries/fmcg"><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img src={require('../../images/services/industries/retail.png')} alt="" />
                                        <div className="figcaption">
                                            <h4>Retail</h4>
                                            <p>Efficient and scalable logistics tailored for the retail industry, supporting inventory management and timely delivery to meet consumer demand.</p>
                                            <NavLink to="/industries/retail"><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img src={require('../../images/services/industries/electronics.png')} alt=""/>
                                        <div className="figcaption bg-dark">
                                            <h4>Electronics</h4>
                                            <p>Secure and precise transportation of high-value electronics, ensuring your technology reaches its destination safely and on time.</p>
                                            <NavLink to="/industries/electronics"><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img src={require('../../images/services/industries/health.png')} alt=""/>
                                        <div className="figcaption bg-dark">
                                            <h4>Healthcare</h4>
                                            <p>Sensitive and reliable logistics for healthcare products, from pharmaceuticals to medical equipment, ensuring quality and compliance.</p>
                                            <NavLink to="/industries/healthcare"><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img src={require('../../images/services/industries/auto.png')} alt=""/>
                                        <div className="figcaption bg-dark">
                                            <h4>Automotive</h4>
                                            <p>Seamless logistics for the automotive industry, ensuring the safe and timely transport of vehicles and components worldwide.</p>
                                            <NavLink to="/industries/automotive"><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Specialization5;