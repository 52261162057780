import React from 'react';
import { NavLink } from 'react-router-dom';

var bnr1 = require('./../../images/background/bg-6.png');

class Specialization3 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-dark mobile-page-padding" style={{backgroundImage:"url(" +  + ")"}}>
                    <div className="container">
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="section-head m-b50 text-white text-center">
                                <h3 className="m-t0"></h3>
                                <span></span>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img src={require('../../images/services/1.jpg')}  alt=""/>
                                        <div className="figcaption">
                                            <h4>Ocean Freight</h4>
                                            <p>Global shipping solutions, ensuring cost-effective and reliable cargo delivery.</p>
                                            <NavLink to="/services/oceanfreight"><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img src={require('../../images/services/2.jpg')} alt="" />
                                        <div className="figcaption">
                                            <h4>Air Freight</h4>
                                            <p>Fast and secure air transport for your time-sensitive shipments.</p>
                                            <NavLink to="/services/airfreight"><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img src={require('../../images/services/3.jpg')} alt=""/>
                                        <div className="figcaption bg-dark">
                                            <h4>NVOCC</h4>
                                            <p>Flexible containerized logistics tailored to meet your unique shipping needs.</p>
                                            <NavLink to="/services/nvocc"><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img src={require('../../images/services/4.jpg')}  alt=""/>
                                        <div className="figcaption">
                                            <h4>Container Trading</h4>
                                            <p>High-quality shipping containers for sale, meeting diverse logistics demands.</p>
                                            <NavLink to="/services/containertrading"><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img src={require('../../images/services/5.jpg')} alt="" />
                                        <div className="figcaption">
                                            <h4>Project Logistics</h4>
                                            <p>Expert management for complex, oversized, and time-critical cargo projects globally.</p>
                                            <NavLink to="/services/projectslogistics"><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img src={require('../../images/services/6.jpg')} alt=""/>
                                        <div className="figcaption bg-dark">
                                            <h4>Warehousing</h4>
                                            <p>Secure and efficient storage solutions, streamlining your supply chain operations.</p>
                                            <NavLink to="/services/warehousing"><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Specialization3;