import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Faq from "../Elements/Faq";
import { useModal } from "../../context/ModelContext";

const Warehousing = () => {
  const { handleShow } = useModal();
  return (
    <>
      <Header />
      <div className="page-content">
        <div className="section-full p-t80 bg-white">
          <div className="container">
            <div className="section-content ">
              <div className="m-service-containt text-black">
                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <div className="service-about-left">
                      <div className="mt-media">
                        <img
                          src={require("./../../images/services/6.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 col-sm-12">
                    <div className="service-about-right m-b30">
                      <h3 className="m-t0">Warehousing</h3>
                      <p>
                        At Time Global Shipping, we offer secure and efficient
                        warehousing solutions designed to optimize your supply
                        chain. Our state-of-the-art storage facilities are
                        equipped with advanced inventory management systems,
                        providing both short-term and long-term storage options.
                        We handle a wide variety of goods, including
                        temperature-sensitive and high-value items, ensuring
                        their safety and integrity. Our warehousing services
                        also include value-added options such as pick and pack,
                        order fulfillment, and distribution, helping you
                        streamline your operations and reduce lead times.
                      </p>
                      <p>
                        We understand that every business has unique storage
                        needs, which is why we provide customized warehousing
                        solutions tailored to your requirements. Our modern
                        facilities are strategically located to support
                        efficient distribution and minimize transportation
                        costs. With a focus on reliability and flexibility, we
                        help you maintain optimal inventory levels and improve
                        overall supply chain performance. Partner with Time
                        Global Shipping for warehousing services that enhance
                        your operational efficiency and provide peace of mind
                        for your logistics needs.
                      </p>
                      <div>
                        <button
                          className="m-b15 site-button btn-effect m-r15 text-uppercase"
                          type="button"
                          onClick={() => {
                            handleShow();
                          }}
                        >
                          Get a Quote
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Faq />
        <Footer />
      </div>
    </>
  );
};

export default Warehousing;
