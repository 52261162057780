import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
// import Banner from './../Elements/Banner';
// import AboutCompany from '../Elements/AboutCompany';
// import { NavLink } from 'react-router-dom';
// import Specialization3 from '../Elements/Specialization3';
// import OurServices3 from '../Elements/OurServices3';
// import OurValue3 from '../Elements/OurValue3';
// import OurProject3 from '../Elements/OurProject3';
import Faq from '../Elements/Faq';
import { useModal } from "../../context/ModelContext";

const Nvocc = () => {
  const { handleShow } = useModal();
  return (
    <>
      <Header />
      <div className="page-content">
                    <div className="section-full p-t80 bg-white">
                    <div className="container">
                        <div className="section-content ">
                            <div className="m-service-containt text-black">
                                <div className="row">
                                    <div className="col-md-5 col-sm-12">
                                        <div className="service-about-left">
                                            <div className="mt-media">
                                                <img src={require('./../../images/services/3.jpg')} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-sm-12">
                                        <div className="service-about-right m-b30">
                                            <h3 className="m-t0">Nvocc</h3>
                                            <p>As a leading Non-Vessel Operating Common Carrier (NVOCC), Time Global Shipping provides efficient and flexible containerized logistics solutions tailored to your business needs. Our services include both full container load (FCL) and less than container load (LCL) options, allowing you to optimize shipping costs and capacity. We manage every aspect of the container shipping process, from booking and documentation to cargo tracking and delivery. Whether you’re handling import, export, or cross-trade shipments, our NVOCC solutions ensure smooth and reliable operations across international borders.</p>
                                            <p>
                                            Our strong network of global partners and carriers enables us to provide competitive rates and flexible scheduling, ensuring your shipments move seamlessly. At Time Global Shipping, we understand that no two shipments are the same, which is why we offer personalized logistics plans to suit your unique requirements. From small businesses to large enterprises, our NVOCC services are designed to enhance your supply chain’s efficiency and reliability. With a focus on customer satisfaction, we ensure your cargo is handled with care and precision, providing peace of mind from origin to destination.
                                            </p>
                                            <div>
                                                <button
                                                    className="m-b15 site-button btn-effect m-r15 text-uppercase"
                                                    type="button"
                                                    onClick={() => {
                                                        handleShow();
                                                    }}
                                                >
                                                    Get a Quote
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
          </div>
        </div>
      </div>
      <Faq />
      <Footer />
    </>
  );
};

export default Nvocc;
