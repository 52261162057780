import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Faq from '../Elements/Faq';

class Electronics extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <div className="section-full p-t80 bg-white">
                    <div className="container">
                        <div className="section-content ">
                            <div className="m-service-containt text-black">
                                <div className="row">
                                    <div className="col-md-5 col-sm-12">
                                        <div className="service-about-left">
                                            <div className="mt-media">
                                                <img src={require('./../../images/services/industries/electronics.png')} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-sm-12">
                                        <div className="service-about-right m-b30">
                                            <h3 className="m-t0">Electronics</h3>
                                            <p>The electronics industry requires precise and secure logistics solutions to ensure the safe transportation of high-value and sensitive products. At Time Global Shipping, we specialize in providing tailored logistics services for the electronics sector, from consumer electronics and components to specialized equipment. Our expertise ensures that your shipments, whether small or large, are handled with care and delivered in perfect condition, minimizing the risk of damage or delays.</p>
                                            <p>
                                            We offer solutions such as secure packaging, temperature-controlled environments, and advanced tracking to safeguard your valuable electronics throughout the supply chain. Whether you're shipping within the country or internationally, our team is committed to providing reliable and timely logistics services. Trust us to keep your electronics supply chain moving efficiently, ensuring that your products reach their destinations on time and in optimal condition.
                                            </p>
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Faq />
                <Footer />
                    
                </div>

                
               

            </>
        );
    };
};

export default Electronics;