import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Faq from '../Elements/Faq';

class Retail extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <div className="section-full p-t80 bg-white">
                    <div className="container">
                        <div className="section-content ">
                            <div className="m-service-containt text-black">
                                <div className="row">
                                    <div className="col-md-5 col-sm-12">
                                        <div className="service-about-left">
                                            <div className="mt-media">
                                                <img src={require('./../../images/services/industries/retail.png')} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-sm-12">
                                        <div className="service-about-right m-b30">
                                            <h3 className="m-t0">Retail</h3>
                                            <p>The fast-moving consumer goods (FMCG) industry relies on timely and efficient logistics to keep up with high demand and rapid product turnover. At Time Global Shipping, we offer tailored logistics solutions designed to support the unique requirements of FMCG manufacturers and distributors. From handling large volumes of perishable items to managing the transportation of packaged goods, we ensure that your products reach their destinations quickly and securely. Our expertise allows us to streamline your supply chain, minimizing delays and ensuring the integrity of your shipments.</p>
                                            <p>
                                            We also provide specialized solutions for temperature-sensitive products and high-volume shipments, ensuring that your goods are transported under the right conditions. Whether you're shipping locally or globally, our team is committed to maintaining the quality of your products and meeting tight delivery deadlines. Partner with us for reliable, cost-effective logistics that keep your FMCG business running smoothly and efficiently.
                                            </p>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Faq />
                <Footer />
                    
                </div>

                
               

            </>
        );
    };
};

export default Retail;