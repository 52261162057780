import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Faq from '../Elements/Faq';

class Automotive extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <div className="section-full p-t80 bg-white">
                    <div className="container">
                        <div className="section-content ">
                            <div className="m-service-containt text-black">
                                <div className="row">
                                    <div className="col-md-5 col-sm-12">
                                        <div className="service-about-left">
                                            <div className="mt-media">
                                                <img src={require('./../../images/services/industries/auto.png')} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-sm-12">
                                        <div className="service-about-right m-b30">
                                            <h3 className="m-t0">Automotive</h3>
                                            <p>The automotive industry depends on reliable logistics to ensure the smooth transportation of parts, vehicles, and machinery. At Time Global Shipping, we provide specialized shipping solutions designed to meet the specific needs of automotive manufacturers and suppliers. From handling components for assembly lines to transporting finished vehicles to their final destinations, we have the expertise to move your goods safely and efficiently. Our experienced team ensures that every shipment is carefully managed, with a focus on safety and timeliness.</p>
                                            <p>In addition to standard shipping services, we offer tailored logistics solutions to accommodate oversized and specialized cargo, ensuring seamless integration into your supply chain. Whether you're shipping within the country or internationally, we understand the importance of meeting deadlines and maintaining the integrity of your shipments. Partner with us for dependable and cost-effective logistics services that help your automotive business run smoothly.</p>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Faq />
                <Footer />
                    
                </div>

                
               

            </>
        );
    };
};

export default Automotive;