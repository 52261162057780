import React from "react";
import { useModal } from "../../context/ModelContext"; // Import the context

const Form = () => {
  const { showModal, handleClose } = useModal();

  return (
    <>
      <div
        style={{
          display: showModal ? "block" : "none",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
        className={`modal  ${showModal ? "show" : "fade"}`}
        id="staticBackdrop2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel2"
        aria-hidden="true"
      >
        <div className="modal-dialog d-flex justify-content-center">
          <div className="modal-content w-75">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel2">
                Get quote
              </h5>
              <button
                onClick={handleClose}
                type="button"
                data-mdb-button-init
                data-mdb-ripple-init
                className="btn-close"
                data-mdb-dismiss="modal"
                aria-label="Close"
                style={{
                  position: "absolute",
                  right: "1.5rem",
                  top: "1.5rem",
                }}
              >
                x
              </button>
            </div>
            <div className="modal-body p-4">
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <div data-mdb-input-init className="form-outline mb-4">
                  <label className="form-label" for="name2">
                    Name
                  </label>
                  <input type="text" id="name2" className="form-control" />
                </div>

                <div data-mdb-input-init class="form-outline mb-4">
                  <label className="form-label" for="email2">
                    Email address
                  </label>
                  <input type="email" id="email2" className="form-control" />
                </div>
                <div data-mdb-input-init class="form-outline mb-4">
                  <label className="form-label" for="phone2">
                    Phone
                  </label>
                  <input type="text" id="phone2" className="form-control" />
                </div>
                <div data-mdb-input-init class="form-outline mb-4">
                  <label className="form-label" for="password2">
                    Message
                  </label>
                  <input type="text" id="message2" className="form-control" />
                </div>

                <button
                  type="submit"
                  data-mdb-button-init
                  data-mdb-ripple-init
                  className="btn btn-primary btn-block"
                >
                  Get quote
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
