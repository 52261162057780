import React from "react";
import Components from "./components/Components";
import ScrolToTop from "./components/Elements/ScrolToTop";
import Loader from "./components/Elements/Loader";
import WhatsappChat from "./components/Common/WhatsappChat";
import Form from "./components/Common/Form";
import { ModalProvider } from "./context/ModelContext";
import "./index.css";

const App = () => {
  return (
    <ModalProvider>
      <Form />
      <div className="App">
        <Components />
        <ScrolToTop />
        <WhatsappChat />
        <Loader />
      </div>
    </ModalProvider>
  );
};

export default App;
