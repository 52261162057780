import React from "react";

const WhatsappChat = () => {
  return (
    <a
      href="https://api.whatsapp.com/send?phone=/?phone=+97143254878&text=I+am+interested+in+Time+Global+Shipping"
      className="whatsappchat"
    >
      <i className="fa fa-whatsapp"></i>
    </a>
  );
};

export default WhatsappChat;
