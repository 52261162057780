import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Faq from '../Elements/Faq';

class Construction extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <div className="section-full p-t80 bg-white">
                    <div className="container">
                        <div className="section-content ">
                            <div className="m-service-containt text-black">
                                <div className="row">
                                    <div className="col-md-5 col-sm-12">
                                        <div className="service-about-left">
                                            <div className="mt-media">
                                                <img src={require('./../../images/services/industries/oil.png')} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-sm-12">
                                        <div className="service-about-right m-b30">
                                            <h3 className="m-t0">Construction</h3>
                                            <p>The construction industry depends on the timely delivery of materials, machinery, and equipment to keep projects on track. At Time Global Shipping, we provide specialized logistics services tailored to the construction sector, ensuring that your shipments arrive at job sites when and where they're needed. From transporting heavy machinery and building materials to handling oversized equipment, we have the expertise and resources to manage the logistics of your construction projects efficiently.</p>
                                            <p>
                                            Our logistics services for the construction industry include the safe transportation of bulk materials, heavy equipment, and prefabricated structures. Whether your project is local or international, our team is experienced in navigating complex supply chain challenges, ensuring timely and reliable delivery. Trust us to provide the logistics solutions needed to keep your construction projects moving forward without delays.
                                            </p>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Faq />
                <Footer />
                    
                </div>

                
               

            </>
        );
    };
};

export default Construction;