import React from "react";
import { NavLink } from "react-router-dom";

class Faq extends React.Component {
  render() {
    return (
      <>
        <div className="section-full p-t80 p-b50 bg-white inner-page-padding">
          <div className="container">
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  {/* TITLE START */}
                  <div className="section-head">
                    <div className="mt-separator-outer separator-left">
                      <div className="mt-separator">
                        <h2 className="text-uppercase sep-line-one ">
                          <span className="font-weight-300 text-primary">
                            Some
                          </span>{" "}
                          FAQ
                        </h2>
                      </div>
                    </div>
                  </div>
                  {/* TITLE END */}
                  <div className="section-content p-b30">
                    <div className="mt-accordion acc-bg-gray" id="accordion5">
                      <div className="panel mt-panel">
                        <div className="acod-head acc-actives">
                          <h6 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseOne5"
                              data-parent="#accordion5"
                            >
                              How do I choose between sea and air freight?
                              <span className="indicator">
                                <i className="fa" />
                              </span>
                            </a>
                          </h6>
                        </div>
                        <div
                          id="collapseOne5"
                          className="acod-body collapse in"
                        >
                          <div className="acod-content p-tb15">
                            <p>
                              {" "}
                              Choosing depends on your priority: air freight is faster but more expensive, while sea freight is cost-effective for larger shipments and less time-sensitive cargo.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel mt-panel">
                        <div className="acod-head">
                          <h6 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseTwo5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              What is NVOCC, and how can it benefit my business?
                              <span className="indicator">
                                <i className="fa" />
                              </span>
                            </a>
                          </h6>
                        </div>
                        <div id="collapseTwo5" className="acod-body collapse">
                          <div className="acod-content p-tb15">
                          NVOCC (Non-Vessel Operating Common Carrier) provides flexible containerized shipping without owning vessels, offering competitive rates and tailored logistics solutions.
                          </div>
                        </div>
                      </div>
                      <div className="panel mt-panel">
                        <div className="acod-head">
                          <h6 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseThree5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Do you handle customs clearance for shipments?
                              <span className="indicator">
                                <i className="fa" />
                              </span>
                            </a>
                          </h6>
                        </div>
                        <div id="collapseThree5" className="acod-body collapse">
                          <div className="acod-content p-tb15">
                          Yes, we offer end-to-end services, including customs clearance, to ensure smooth and compliant cargo delivery.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
              </div>
            </div>
          </div>
          {/* FAQ */}
        </div>
      </>
    );
  }
}

export default Faq;
