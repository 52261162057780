import React from 'react';
import { NavLink } from 'react-router-dom';

class AboutCompany extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 bg-white">
                    <div className="container">
                        <div className="section-content ">
                            <div className="m-service-containt text-black">
                                <div className="row">
                                    <div className="col-md-5 col-sm-12">
                                        <div className="service-about-left">
                                            <div className="mt-media">
                                                <img src={require('./../../images/s-1.png')} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-sm-12">
                                        <div className="service-about-right m-b30">
                                            <h3 className="m-t0">Empowering global trade through seamless logistics, driven by innovation, reliability, and a commitment to excellence.</h3>
                                            <p>At Time Global Shipping, we pride ourselves on providing innovative and reliable logistics solutions tailored to your needs. From seamless sea and air freight services to specialized project cargo handling and secure warehousing, we offer comprehensive support for your supply chain. Our team is dedicated to ensuring efficiency, transparency, and customer satisfaction at every stage of the journey. By leveraging advanced technology and global expertise, we deliver results that drive success. Trust us to handle your logistics challenges with precision and care, ensuring your business stays ahead in a competitive market.</p>
                                            <div className="call-for-quote-outer">
                                                <div className="call-quote">
                                                    <span>Call For a Quote:</span>
                                                    <h4>+971 4325 48 78</h4>
                                                </div>
                                                <div className="call-estimate bg-dark">
                                                <NavLink to={"#"} className="site-button-secondry btn-effect">Online Estimate Form</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default AboutCompany;