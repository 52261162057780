import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Faq from '../Elements/Faq';

class Healthcare extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <div className="section-full p-t80 bg-white">
                    <div className="container">
                        <div className="section-content ">
                            <div className="m-service-containt text-black">
                                <div className="row">
                                    <div className="col-md-5 col-sm-12">
                                        <div className="service-about-left">
                                            <div className="mt-media">
                                                <img src={require('./../../images/services/industries/health.png')} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-sm-12">
                                        <div className="service-about-right m-b30">
                                            <h3 className="m-t0">HealthCare</h3>
                                            <p>In the healthcare industry, precision and reliability are essential, especially when it comes to the transportation of medical equipment, pharmaceuticals, and other critical supplies. At Time Global Shipping, we offer specialized logistics solutions tailored to the healthcare sector, ensuring that your sensitive cargo is handled with the utmost care. Whether you're shipping delicate medical devices or time-sensitive pharmaceuticals, our team ensures that your shipments are delivered safely and on time, adhering to all relevant industry regulations.</p>
                                            <p>Our healthcare logistics services include temperature-controlled shipping, secure transportation of high-value equipment, and the handling of hazardous materials. With a focus on compliance and safety, we ensure that your supply chain operates seamlessly, helping you meet the demands of hospitals, clinics, and medical suppliers. Count on us to provide reliable and efficient logistics solutions that support the healthcare sector's critical needs.</p>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Faq />
                <Footer />
                    
                </div>

                
               

            </>
        );
    };
};

export default Healthcare;