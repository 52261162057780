import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Faq from '../Elements/Faq';
import { useModal } from "../../context/ModelContext";

const OilGas = () => {
    const { handleShow } = useModal();
    return (
            <>
                <Header />
                <div className="page-content">
                    <div className="section-full p-t80 bg-white">
                    <div className="container">
                        <div className="section-content ">
                            <div className="m-service-containt text-black">
                                <div className="row">
                                    <div className="col-md-5 col-sm-12">
                                        <div className="service-about-left">
                                            <div className="mt-media">
                                                <img src={require('./../../images/services/industries/oil.png')} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-sm-12">
                                        <div className="service-about-right m-b30">
                                            <h3 className="m-t0">Oil & Gas</h3>
                                            <p>The oil and gas industry is one of the most demanding sectors, requiring precision, reliability, and expertise in logistics. At Time Global Shipping, we specialize in providing tailored shipping and logistics solutions for the transportation of oil, gas, and related equipment. Whether you're moving drilling equipment, pipelines, or hazardous materials, we have the experience and resources to ensure that your cargo reaches its destination safely and on time. With a deep understanding of the industry’s regulatory requirements, we work diligently to meet safety and compliance standards at every step of the way.</p>
                                            <p>
                                            Our logistics services for the oil and gas industry include handling oversized and heavy equipment, managing hazardous materials, and ensuring secure transportation to offshore and onshore locations. With a global network of partners, we facilitate seamless cross-border shipments, ensuring that your operations remain uninterrupted. Whether your project involves transporting machinery to a remote site or moving large quantities of oil and gas supplies, we provide the expertise and support needed to keep your business moving forward.
                                            </p>
                                            <div>
                                                <button
                                                    className="m-b15 site-button btn-effect m-r15 text-uppercase"
                                                    type="button"
                                                    onClick={() => {
                                                        handleShow();
                                                    }}
                                                >
                                                    Get a Quote
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Faq />
                <Footer />
            </div>
        </>
    );
};

export default OilGas;